import {axiosInstance as axios} from '../../utility/utils'
import { domain } from '../../utility/constants';

export function loginAPI(payload){
    return axios.post(`${domain}/admin/user/auth/signin`,payload , {handlerEnabled: false})
}

export function updateProfileAPI(id, payload){
    return axios.put(`${domain}/admin/user/${id}`,payload)
}

export function forgotPasswordAPI(email){
    return axios.get(`${domain}/admin/user/auth/forget/${email}`, {handlerEnabled: false})
}

export function resetPasswordAPI(payload){
    return axios.put(`${domain}/admin/user/auth/password`,payload, {handlerEnabled: false})
}

export function changePasswordAPI(payload){
    return axios.post(`${domain}/admin/changePassword`,payload)
}

export function logoutAPI(payload){
    return axios.post(`${domain}/admin/logout`,payload, {handlerEnabled: false})
}