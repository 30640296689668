import { combineReducers } from "redux"
import { getSubAdmin } from "./subAdmin"
import { updateSubAdmin } from "./updateSubAdmin"
import { createSubAdmin } from "./createSubAdmin";

const subAdminReducers = combineReducers({
  getSubAdmin,
  updateSubAdmin,
  createSubAdmin
})

export default subAdminReducers
