import {axiosInstance as axios} from '../../utility/utils'
import { domain } from '../../utility/constants'

export const HtmlContentApi = {
    get: (type) => axios.get(`${domain}/admin/static/${type}`),
    post: (type, data) => axios.post(`${domain}/admin/static/${type}`, data)
}

export const getFaqsApi = (payload) => axios.get(`${domain}/admin/static/faqs?page=${payload.page}&limit=${payload.limit}`);

export const postFaqApi = (data) => axios.post(`${domain}/admin/static/faqs`, data);

export const putFaqApi = (id, data) => axios.put(`${domain}/admin/static/faqs/${id}`, data);

