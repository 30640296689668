import types from "../types";

const deliveryChargeSlot = {
    start_distance: "---",
    price: 0,
    end_distance: "---",
};
const initialState = {
    fetching: false,
    delivery: {
        charges: [
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
            deliveryChargeSlot,
        ],
    },
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.SET_DELIVERY_CHARGES:
            return {
                ...state,
                delivery: { ...state.delivery, ...action.payload },
            };
        case types.FETCH_DELIVERY:
            return { ...state, fetching: action.payload };
        default:
            return { ...state };
    }
};
