import { axiosInstance as axios } from "../../utility/utils";
import { domain } from "../../utility/constants";

export const getOrdersApi = (payload) =>
    axios.get(
        `${domain}/admin/orders?pageNo=${payload.page}&search=${payload.search}&startDate=${payload.startDate}&endDate=${payload.endDate}`
    );

export const getOrdersDeliveryTeamApi = (payload) =>
    axios.get(
        `${domain}/admin/orders/delivery/team/order/list?pageNo=${payload.page}&search=${payload.search}&startDate=${payload.startDate}&endDate=${payload.endDate}`
    );

export function updateOrderFlagApi({ orderId }) {
    return axios.put(`${domain}/admin/orders/flagged-order/${orderId}`);
}

export function updateOrderStatusApi(payload) {
    return axios.put(`${domain}/admin/orders/change/order/status`, payload);
}

export const getSingleOrderApi = (orderId) =>
    axios.get(`${domain}/admin/orders/${orderId}`);

export const exportOrderApi = (payload) =>
    axios.get(
        `${domain}/admin/orders/export?pageNo=${payload.page}&search=${payload.search}&startDate=${payload.startDate}&endDate=${payload.endDate}`
    );
