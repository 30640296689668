import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
    // {
    //   id: "dashboard",
    //   title: "Dashboard",
    //   type: "item",
    //   icon: <Icon.Home size={20} />,
    //   permissions: ["superadmin"],
    //   navLink: "/"
    // },
    {
        id: "manageUsers",
        title: "Manage Users",
        type: "collapse",
        icon: <Icon.Users size={20} />,
        permissions: ["superadmin", "support_engineer", "management_user"],
        children: [
            {
                id: "buyer",
                title: "Manage Buyers",
                type: "item",
                icon: <Icon.Users size={12} />,
                navLink: "/pages/buyer",
                permissions: ["superadmin", "support_engineer"],
            },
            {
                id: "seller",
                title: "Manage Sellers",
                type: "item",
                icon: <Icon.Users size={12} />,
                navLink: "/pages/seller",
                permissions: ["superadmin", "support_engineer"],
            },
            {
                id: "sub-admin",
                title: "Manage Admins",
                type: "item",
                icon: <Icon.UserPlus size={12} />,
                navLink: "/pages/subAdmin",
                permissions: ["management_user"],
            },
        ],
    },
    {
        id: "subscriptions",
        title: "Manage Subscriptions",
        type: "item",
        icon: <Icon.Repeat size={20} />,
        permissions: ["superadmin", "support_engineer"],
        navLink: "/pages/subscriptions",
    },
    {
        id: "offers",
        title: "Manage Offers",
        type: "item",
        icon: <Icon.DollarSign size={20} />,
        permissions: ["superadmin"],
        navLink: "/pages/offers",
    },
    {
        id: "banners",
        title: "Manage Banners",
        type: "item",
        icon: <Icon.Image size={20} />,
        permissions: ["superadmin"],
        navLink: "/pages/banners",
    },
    {
        id: "orders",
        title: "Manage Orders",
        type: "collapse",
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ["superadmin", "management_user", "support_engineer"],
        children: [
            {
                id: "buyer",
                title: "Orders Support Team",
                type: "item",
                icon: <Icon.ShoppingBag size={12} />,
                navLink: "/pages/orders",
                permissions: [
                    "superadmin",
                    "management_user",
                    "support_engineer",
                ],
            },
            {
                id: "seller",
                title: "Orders Delivery Team",
                type: "item",
                icon: <Icon.ShoppingBag size={12} />,
                navLink: "/pages/orders-delivery-team",
                permissions: [
                    "superadmin",
                    "management_user",
                    "support_engineer",
                ],
            },
        ],
    },
    {
        id: "staticContent",
        title: "Manage Static Content",
        type: "collapse",
        icon: <Icon.File size={20} />,
        permissions: ["superadmin"],
        children: [
            {
                id: "about",
                title: "About Us",
                type: "item",
                icon: <Icon.File size={12} />,
                navLink: "/pages/static/aboutUs",
            },
            // {
            //     id: "contact",
            //     title: "Contact Us",
            //     type: "item",
            //     icon: <Icon.File size={12} />,
            //     navLink: "/pages/static/contactUs",
            // },
            {
                id: "terms",
                title: "Terms & Conditions",
                type: "item",
                icon: <Icon.File size={12} />,
                navLink: "/pages/static/tnc",
            },
            {
                id: "faq",
                title: "FAQ",
                type: "item",
                icon: <Icon.File size={12} />,
                navLink: "/pages/faq",
            },
            {
                id: "Privacy",
                title: "Privacy Policy",
                type: "item",
                icon: <Icon.File size={12} />,
                navLink: "/pages/static/privacyPolicy",
            },
        ],
    },
    {
        id: "commissions",
        title: "Manage Commissions",
        type: "item",
        icon: <Icon.CreditCard size={20} />,
        permissions: ["management_user"],
        navLink: "/pages/commissions",
    },
    {
        id: "review",
        title: "Review & Ratings",
        type: "item",
        icon: <Icon.Star size={20} />,
        permissions: ["superadmin"],
        navLink: "/pages/reviews",
    },
    {
        id: "settings",
        title: "Settings",
        type: "collapse",
        icon: <Icon.Settings size={20} />,
        permissions: ["management_user"],
        children: [
            {
                id: "delivery",
                title: "Delivery Charges",
                type: "item",
                icon: <Icon.Truck size={12} />,
                navLink: "/pages/delivery",
                permissions: ["management_user"],
            },
        ],
    },
    {
        id: "sergePercent",
        title: "Serge Percent",
        type: "item",
        icon: <Icon.Percent size={20} />,
        permissions: ["management_user"],
        navLink: "/pages/sergePercent",
    },
    {
        id: "masterOtp",
        title: "Manage Master OTP",
        type: "item",
        icon: <Icon.Key size={20} />,
        permissions: ["management_user"],
        navLink: "/pages/master-otp",
    },
];

export default navigationConfig;
