import { axiosInstance as axios } from "../../utility/utils";
import { domain } from "../../utility/constants";

export const createProductApi = (payload) =>
    axios.post(`${domain}/admin/product`, payload);

export const getProductsApi = (payload) =>
    axios.get(
        `${domain}/admin/product/sellerProductList/${payload.sellerId}/?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
    );

export const updateProductApi = (productId, payload) =>
    axios.put(`${domain}/admin/product/${productId}`, payload);

export const deleteProductApi = (productId) =>
    axios.delete(`${domain}/admin/product/${productId}`);

export const cuisineListApi = () =>
    axios.get(`${domain}/admin/product/search/cuisine`);

export const productImageApi = (productId, payload) =>
    axios.post(
        `${domain}/admin/product/upload/productImages/${productId}`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
