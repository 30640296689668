import types from '../types';
import { HtmlContentApi, getFaqsApi, postFaqApi, putFaqApi } from '../api/staticContent';

const exceptionWrapper = (cb) => async (dispatch) => {
    try {
        dispatch({
            type: types.FETCH_CONTENT,
            payload: true
        });
        await cb();
    } catch(err) {
        console.error(err);
    } finally {
        dispatch({
            type: types.FETCH_CONTENT,
            payload: false
        });
    }
}

export const handleHtmlContent = (mode, type, data = null) => async (dispatch) => {
    await exceptionWrapper(async function handleContentInner() {
        const content = types.contentType[type];
        const contentResponse = await HtmlContentApi[mode](content.endpoint, mode === "post" && data);
        dispatch({
            type: content.action,
            payload: contentResponse && contentResponse.data && contentResponse.data.data && contentResponse.data.data.html
        });

    })(dispatch)
}

export const getFaqs = (payload) => async (dispatch) => {
    await exceptionWrapper(async function getFaqsInner() {
        const faqsResponse = await getFaqsApi(payload);
        dispatch({
            type: types.SET_FAQ,
            payload:
                faqsResponse &&
                faqsResponse.data &&
                faqsResponse.data.data &&
                faqsResponse.data.data.faqs
        });
        dispatch({
            type: types.SET_FAQ_PAGING,
            payload:
                faqsResponse &&
                faqsResponse.data &&
                faqsResponse.data.data &&
                faqsResponse.data.data.paging,
        });
    })(dispatch);
}

export const postFaqs = (data) => async (dispatch) => {
    await exceptionWrapper(async function postFaqInner() {
        await postFaqApi(data);
    })(dispatch);
}

export const putFaqs = (id, data) => async (dispatch) => {
    await exceptionWrapper(async function postFaqInner() {
        const faqResponse = await putFaqApi(id, data);
    })(dispatch);
}