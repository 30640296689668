import types from '../../types';

let initialState = {
    fetching: true,
    buyerData: [],
    ratedBuyerData: []
}


export const manageBuyer = (state = initialState,action) => {

    switch (action.type) {
        case types.GET_STUDENTS:
            return { ...state, fetching: true }
        case types.GET_STUDENTS_SUCCESS:
            return { ...state, fetching: false, buyerData:{...action.payload} };
        case types.GET_STUDENTS_FAILED:
            return { ...state, fetching: false }
        case types.GET_RATED_BUYER:
            return { ...state, fetching: true }
        case types.GET_RATED_BUYER_SUCCESS:
            return { ...state, fetching: false, ratedBuyerData: { ...action.payload } };
        case types.GET_RATED_BUYER_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}