import types from "../types";
import { getRatingApi, deleteRatingApi } from "../api/ratings";
import { toast } from "react-toastify";

const exceptionHandler = (cb) => async (dispatch) => {
    try {
        dispatch({
            type: types.FETCH_RATINGS,
            payload: true,
        });
        const data = await cb();
        return Promise.resolve(data);
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({
            type: types.FETCH_RATINGS,
            payload: false,
        });
    }
};

export const getRatings = (payload) => async (dispatch) => {
    return await exceptionHandler(async () => {
        const ratingResponse = await getRatingApi(payload);
        dispatch({
            type: types.SET_RATINGS,
            payload: ratingResponse.data.data.data,
        });
        return Promise.resolve(ratingResponse);
    })(dispatch);
};

export const deleteRating = (payload) => async (dispatch) => {
    await exceptionHandler(async function deleteRatingInner() {
        const ratingResponse = await deleteRatingApi(payload);
        if (ratingResponse.data.statusCode === 200)
            toast.success(ratingResponse.data.message);
    })(dispatch);
};
