import { domain } from "../../utility/constants";
import { axiosInstance as axios } from "../../utility/utils";

export function getRatedBuyerAPI(payload) {
    if (payload.search)
        return axios.get(
            `${domain}/admin/rating/rated-buyer-list?pageNo=${payload.page}&pageSize=${payload.limit}&search=${payload.search}`
        );
    return axios.get(
        `${domain}/admin/rating/rated-buyer-list?pageNo=${payload.page}&pageSize=${payload.limit}`
    );
    // return axios.get(`${domain}/admin/user/buyer/userList?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}`)
}

export function getRatedSellerAPI(payload) {
    if (payload.search)
        return axios.get(
            `${domain}/admin/rating/rated-seller-list?pageNo=${payload.page}&pageSize=${payload.limit}&search=${payload.search}`
        );
    return axios.get(
        `${domain}/admin/rating/rated-seller-list?pageNo=${payload.page}&pageSize=${payload.limit}`
    );
    // return axios.get(`${domain}/admin/user/seller/userList?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}`)
}

export function getBuyerAPI(payload) {
    return axios.get(
        `${domain}/admin/user/buyer/userList?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}`
    );
}

export function getSellerAPI(payload) {
    return axios.get(
        `${domain}/admin/user/seller/userList?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}`
    );
}

export function deleteBuyerAPI(id) {
    return axios.delete(`${domain}/admin/user/buyer/${id}`);
}

export function deleteSellerAPI(id) {
    return axios.delete(`${domain}/admin/user/seller/${id}`);
}

export function updateSellerAPI(payload) {
    return axios.put(`${domain}/admin/user/seller/profile`, payload);
}

export function getTutorProfileAPI(payload) {
    return axios.post(`${domain}/admin/getTutorProfile`, payload);
}

export function updateBuyerAPI(payload) {
    return axios.put(`${domain}/admin/user/buyer/profile`, payload);
}

export function updateFssaiAPI(id, payload) {
    return axios.post(
        `${domain}/admin/user/seller/upload/fssai/${id}`,
        payload
    );
}

export function saveFormDataApi(payload) {
    return axios.put(`${domain}/admin/user/seller/accountId`, payload);
}

export function updateKitchenDetailsApi(payload) {
    return axios.put(`${domain}/admin/user/seller/kitchen-timing`, payload);
}

export function updateSellerBankApi(payload) {
    return axios.put(`${domain}/admin/user/seller/update-bank-detail`, payload);
}

export function updateSellerDetailsApi(payload) {
    return axios.put(`${domain}/admin/user/seller/profile-update`, payload);
}

export function deleteKitchenImageApi(payload) {
    return axios.post(`${domain}/admin/user/seller/deleteImage`, payload);
}

export function uploadKitchenImageApi(id, payload) {
    console.log("api", [...payload]);
    return axios.post(
        `${domain}/admin/user/seller/upload/restaurantImages/${id}`,
        payload
    );
}

export const exportSellersApi = (payload) =>
    axios.get(`${domain}/admin/user/export/seller`, {
        params: {
            pageNo: payload.page,
            search: payload.search,
            startDate: payload.startDate,
            endDate: payload.endDate,
        },
    });

export const exportBuyersApi = (payload) =>
    axios.get(`${domain}/admin/user/export/buyer`, {
        params: {
            pageNo: payload.page,
            search: payload.search,
            startDate: payload.startDate,
            endDate: payload.endDate,
        },
    });
