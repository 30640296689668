import {axiosInstance as axios} from '../../utility/utils'
import { domain } from '../../utility/constants'

export function getAdminAPI(){
    return axios.get(`${domain}/admin/user`)
}

export function getSubAdminAPI(payload){
    return axios.get(`${domain}/admin/user/list?search=${payload.search || ""}&page=${payload.page}&limit=${payload.limit}`)
}

export function updateSubAdminAPI(payload){
    return axios.put(`${domain}/admin/user/${payload._id}`,{
        fullName: payload.fullName,
        role: payload.role,
        status: payload.status
    })
}

export function createSubAdminAPI(payload){
    return axios.post(`${domain}/admin/user`,{
        fullName: payload.fullName,
        role: payload.role,
        email: payload.email 
    })
}

export function deleteSubAdminAPI(_id){
    return axios.delete(`${domain}/admin/user/${_id}`)
}

