import { combineReducers } from "redux"
import { loginAuth } from "./loginReducer"
import {login} from "./login"
import {profile} from "./profile"
import {managePassword} from "./managePassword"
import {logout} from "./logout"

const authReducers = combineReducers({
  login,
  loginAuth,
  profile,
  managePassword,
  logout
})

export default authReducers
