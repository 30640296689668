import types from '../../types';

let initialState = {
    fetching: true,
    subAdmin: []
}


export const getSubAdmin = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_SUBADMIN:
            return { ...state, fetching: true }
        case types.GET_SUBADMIN_SUCCESS:
            let subAdmin = { ...action.payload };
            return { ...state, fetching: false, subAdmin };
        case types.GET_SUBADMIN_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}