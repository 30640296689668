import { combineReducers } from "redux";
import auth from "./auth/";
import banners from "./banners";
import commission from "./commissions";
import customizer from "./customizer/";
import delivery from "./delivery";
import navbar from "./navbar/Index";
import offers from "./offers";
import orders from "./orders";
import products from "./products";
import ratings from "./ratings";
import staticContent from "./staticContent";
import subAdmin from "./subAdmin";
import users from "./users";
import subscription from "./subscriptions";
import masterOtp from "./masterOtp";

const rootReducer = combineReducers({
    customizer,
    auth,
    navbar,
    subAdmin,
    staticContent,
    users,
    products,
    offers,
    delivery,
    ratings,
    orders,
    commission,
    banners,
    subscription,
    masterOtp,
});

export default rootReducer;
