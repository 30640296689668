import types from "../types";
import {
    getOrdersApi,
    getOrdersDeliveryTeamApi,
    getSingleOrderApi,
} from "../api/orders";

const exceptionHandler = (cb) => async (dispatch) => {
    try {
        dispatch({
            type: types.FETCH_ORDERS,
            payload: true,
        });
        await cb();
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({
            type: types.FETCH_ORDERS,
            payload: false,
        });
    }
};

export const getOrders = (payload) => async (dispatch) => {
    await exceptionHandler(async function getOrdersInner() {
        const orderResponse = await getOrdersApi(payload);
        dispatch({
            type: types.SET_ORDERS,
            payload: orderResponse.data.data.orders,
        });
        dispatch({
            type: types.SET_ORDER_COUNT,
            payload: orderResponse.data.data.count,
        });
    })(dispatch);
};

export const getOrdersDeliveryTeam = (payload) => async (dispatch) => {
    await exceptionHandler(async function getOrdersInner() {
        const orderResponse = await getOrdersDeliveryTeamApi(payload);
        dispatch({
            type: types.SET_ORDERS_DEL_TEAM,
            payload: orderResponse.data.data.orders,
        });
        dispatch({
            type: types.SET_ORDER_COUNT_DEL_TEAM,
            payload: orderResponse.data.data.count,
        });
    })(dispatch);
};

export const getSingleOrder = (payload) => async (dispatch) => {
    await exceptionHandler(async function getSingleOrderInner() {
        const orderResponse = await getSingleOrderApi(payload);
        dispatch({
            type: types.SET_CURRENT_ORDERS,
            payload: orderResponse.data.data[0],
        });
    })(dispatch);
};
