import * as auth from "./auth/index";
import * as banners from "./banner";
import * as commission from "./commission";
import * as delivery from "./delivery";
import * as offers from "./offers";
import * as orders from "./orders";
import * as products from "./products";
import * as ratings from "./ratings";
import * as staticContent from "./staticContent";
import * as subAdmin from "./subAdmin";
import * as users from "./users";
import * as subscriptions from "./subscriptions";

export default {
    ...products,
    ...auth,
    ...subAdmin,
    ...staticContent,
    ...users,
    ...offers,
    ...delivery,
    ...ratings,
    ...orders,
    ...commission,
    ...banners,
    ...subscriptions,
};
