import types from '../../types';

let initialState = {
    fetching: false,
    updatedSubAdmin: {}
}


export const updateSubAdmin = (state = initialState, action) => {

    switch (action.type) {
        case types.UPDATE_SUBADMIN:
            return { ...state, fetching: true, updatedSubAdmin:{data:{_id:action.payload}} }
        case types.UPDATE_SUBADMIN_SUCCESS:
            let updatedSubAdmin = { ...action.payload };
            return { ...state, fetching: false, updatedSubAdmin };
        case types.UPDATE_SUBADMIN_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}