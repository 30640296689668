import types from "../types";
const initialState = {
    fetching: false,
    orders: [],
    currentOrder: null,
    count: 0,
    ordersDelTeam: [],
    currentOrdersDelTeam: null,
    countDelTeam: 0,
};

export default function ordersReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_ORDERS:
            return { ...state, orders: action.payload };
        case types.FETCH_ORDERS:
            return { ...state, fetching: action.payload };
        case types.SET_CURRENT_ORDERS:
            return { ...state, currentOrder: action.payload };
        case types.SET_ORDER_COUNT:
            return { ...state, count: action.payload };
        case types.SET_ORDERS_DEL_TEAM:
            return { ...state, ordersDelTeam: action.payload };
        case types.SET_CURRENT_ORDERS_DEL_TEAM:
            return { ...state, currentOrdersDelTeam: action.payload };
        case types.SET_ORDER_COUNT_DEL_TEAM:
            return { ...state, countDelTeam: action.payload };
        default:
            return { ...state };
    }
}
