import types from '../../types';
import { saveUser } from '../../../utility/utils';

let initialState = {
    fetching: false,
    user: {},
    resetUser: {}
}


export const managePassword = (state = initialState, action) => {

    switch (action.type) {
        case types.FORGOT_PASSWORD:
            return { ...state, fetching: true }
        case types.FORGOT_PASSWORD_SUCCESS:
            let user = { ...action.payload };
            return { ...state, fetching: false, user, logout: false };
        case types.FORGOT_PASSWORD_FAILED:
            return { ...state, fetching: false }
        case types.RESET_PASSWORD:
            return { ...state, fetching: true }
        case types.RESET_PASSWORD_SUCCESS:
            let resetUser = { ...action.payload };
            saveUser({...action.payload})
            return { ...state, fetching: false, resetUser, logout: false };
        case types.RESET_PASSWORD_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}