import types from '../../types';
import {store} from '../../storeConfig/store'
import {loginAPI, updateProfileAPI, forgotPasswordAPI, changePasswordAPI, 
        resetPasswordAPI, logoutAPI} from '../../api/auth';
import { getUser } from '../../../utility/utils';

const {dispatch} = store;
export function login(user){
    dispatch({
        type: types.AUTH_LOGIN
    });

    return new Promise((resolve, reject) => loginAPI(user)
    .then(res => {
        dispatch({
            type: types.AUTH_SUCCESS,
            payload: res.data.data
        });
        dispatch({
            type: types.UPDATE_PROFILE_SUCCESS,
            payload: res.data.data
        });

        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.AUTH_FAILED
        });

        return reject(err);
    })
)
}

export function updateProfile(id, payload){
    dispatch({
        type: types.UPDATE_PROFILE
    });

    return new Promise((resolve, reject) => updateProfileAPI(id, payload)
    .then(res => {
        const { token } = getUser() || {};
        let profile = { ...res.data.data, token };
        dispatch({
            type: types.UPDATE_PROFILE_SUCCESS,
            payload: profile
        });

        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.UPDATE_PROFILE_FAILED
        });

        return reject(err);
    })
)
}

export function forgotPassword(payload){
    dispatch({
        type: types.FORGOT_PASSWORD
    });

    return new Promise((resolve, reject) => forgotPasswordAPI(payload.email)
    .then(res => {
        dispatch({
            type: types.FORGOT_PASSWORD_SUCCESS        });

        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.FORGOT_PASSWORD_FAILED
        });

        return reject(err);
    })
)
}

export function changePassword(payload){
    dispatch({
        type: types.RESET_PASSWORD
    });

    return new Promise((resolve, reject) => changePasswordAPI(payload)
    .then(res => {
        const user = getUser();
        const updatedUser = {...user, token:res.data.data.userVerifyToken}
        dispatch({
            type: types.RESET_PASSWORD_SUCCESS,
            payload: updatedUser
        });

        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.RESET_PASSWORD_FAILED
        });

        return reject(err);
    })
)
}

export function resetPassword(payload){
    dispatch({
        type: types.FORGOT_PASSWORD
    });

    return new Promise((resolve, reject) => resetPasswordAPI(payload)
    .then(res => {
        dispatch({
            type: types.FORGOT_PASSWORD_SUCCESS
        });

        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.FORGOT_PASSWORD_FAILED
        });

        return reject(err);
    })
)
}

export function logOutAction(payload){
    dispatch({
        type: types.AUTH_LOGOUT
    });

    return new Promise((resolve, reject) => logoutAPI(payload)
    .then(res => {
        dispatch({
            type: types.AUTH_LOGOUT_SUCCESS,
            payload: res.data.data
        });
        
        return resolve(res.data);
    })
    .catch(err => {
        dispatch({
            type: types.AUTH_LOGOUT_FAILED
        });

        return reject(err);
    })
)
}
