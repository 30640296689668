import types from "../types";

const initialState = {
    isFetching: false,
    data: {
        masterOtp: "",
        masterOtpId: null,
    },
    isUpdating: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_MASTER_OTP:
            return { ...state, isFetching: true };
        case types.GET_MASTER_OTP_SUCCESS:
            return { ...state, data: action.payload, isFetching: false };
        case types.GET_MASTER_OTP_FAILED:
            return { ...state, isFetching: false };
        case types.UPDATE_MASTER_OTP:
            return { ...state, isUpdating: true };
        case types.UPDATE_MASTER_OTP_SUCCESS:
            return { ...state, isUpdating: false };
        case types.UPDATE_MASTER_OTP_FAILED:
            return { ...state, isUpdating: false };
        default:
            return { ...state };
    }
};
