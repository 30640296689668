import types from '../../types';
import {saveUser, getUser} from '../../../utility/utils'

let initialState = {
    fetching: false,
    profile: getUser() || {}
}


export const profile = (state = initialState, action) => {

    switch (action.type) {
        case types.UPDATE_PROFILE:
            return { ...state, fetching: true }
        case types.UPDATE_PROFILE_SUCCESS:
            let savedProfile = { ...action.payload };
            saveUser(savedProfile);
            return { ...state, fetching: false, profile:savedProfile, logout: false };
        case types.UPDATE_PROFILE_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}