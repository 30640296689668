import { store } from "../storeConfig/store";
import types from "../types";
import { getSubscriptionsAPI } from "../api/subscriptions";

const { dispatch } = store;

export function getSubscriptions(payload) {
    dispatch({
        type: types.GET_SUBSCRIPTIONS,
    });

    return new Promise((resolve, reject) =>
        getSubscriptionsAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data.result,
                    type: types.GET_SUBSCRIPTIONS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_SUBSCRIPTIONS_FAILED,
                });

                return reject(err);
            })
    );
}
