import axios from "axios";
import { history } from "../history";
import { toast } from "react-toastify";
var numeral = require("numeral");

const domain = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
    baseURL: domain,
});

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
        ? false
        : true;
};

const successHandler = (response) => {
    return response;
};

const errorHandler = (error) => {
    const status = error && error.response && error.response.status;
    const expired = false;
    if (status === 401) {
        const token = getUser() && getUser().token;
        if (expired) {
            axiosInstance
                .post(`${domain}/admin/user/auth/refresh`, { token })
                .then((response) => {
                    toast.success("Token refreshed! Try Again.");
                    const adminData = JSON.parse(
                        localStorage.getItem("userAdmin")
                    );
                    adminData.token = response.data.token;
                    localStorage.setItem(
                        "userAdmin",
                        JSON.stringify(adminData)
                    );
                    return Promise.resolve({ ...response.data });
                });
        } else {
            window.localStorage.removeItem("userAdmin");
            history.push("/pages/login");
        }
    }

    if (status === 500) {
        toast.error("Internal server error!");
        return Promise.reject({ ...error });
    }

    if (status === 400) {
        toast.error(error.response.data.message);
        return Promise.reject({ ...error });
    }

    return Promise.reject({ ...error });
};

const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {
        const auth_token = JSON.parse(localStorage.getItem("userAdmin")).token;

        if (auth_token) {
            request.headers["Authorization"] = "Bearer " + auth_token;
        }
    }
    return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);

export const setUser = (data) => {
    return localStorage.setItem("user", JSON.stringify(data));
};

export const removeUser = () => localStorage.removeItem("user");

export const route = (e, path, state) => {
    e.preventDefault();
    history.push(path, state);
};

export function getUser() {
    if (window && window.localStorage) {
        return JSON.parse(window.localStorage.getItem("userAdmin"));
    }
    return null;
}

export function saveUser(value) {
    if (window && window.localStorage) {
        return window.localStorage.setItem("userAdmin", JSON.stringify(value));
    }
    return null;
}

export function textTrim(text, len = 20) {
    if (text.length <= len) return text;
    return text.substring(0, len - 1) + "...";
}

export function getObject(key) {
    if (window && window.localStorage) {
        return window.localStorage.getItem(key);
    }
}

export function isLoggedIn(userType) {
    let session = getObject(userType) || {};
    session = Object.keys(session).length && JSON.parse(session);
    let accessToken = (session && session.userVerifyToken) || "";
    return accessToken;
}

export const dateHelper = (date, addDate, addMonth) => {
    const newDate = date && new Date(date);
    if (addDate) newDate.setDate(newDate.getDate() + addDate);
    if (addMonth) newDate.setMonth(newDate.getMonth() + addMonth);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    return date
        ? `${year}-${month < 10 ? `0${month}` : `${month}`}-${
              day < 10 ? `0${day}` : `${day}`
          }`
        : null;
};

export const formatData = (data, totalRevenue) => {
    if (totalRevenue) return numeral(data).format("0,0.00");
    else return numeral(data).format("0,0");
};

/**
 * save array buffer into file
 * @param {ArrayBuffer} data data of document
 * @param {string} fileType mime type of file
 * @param {string} fileName name of file
 */

export const saveFile = (data, fileType, fileName) => {
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(
        new Blob([data], {
            type: fileType,
        })
    );
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
