import types from "../types";
import { toast } from "react-toastify";
import { getDeliveryChargesApi, updateDeliveryChargesApi } from '../api/delivery';

const exceptionWrapper = (cb) => async (dispatch) => {
    try{
        dispatch({
            type: types.FETCH_DELIVERY,
            payload: true
        });
        await cb();
    } catch(e) {
        console.error(e);
    }finally {
        dispatch({
            type: types.FETCH_DELIVERY,
            payload: false
        });
    }
}

export const getDeliveryCharges = () => async (dispatch) => {
    await exceptionWrapper(async function getDeliveryInner() {
        const deliveryResponse = await getDeliveryChargesApi();
        dispatch({
            type: types.SET_DELIVERY_CHARGES,
            payload: deliveryResponse.data ? deliveryResponse.data.data : {},
        });
    })(dispatch)
}

export const updateDeliveryCharges = (payload) => async (dispatch) => {
    await exceptionWrapper(async function updateDeliveryInner() {
        const deliveryResponse = await updateDeliveryChargesApi(payload);
        if (deliveryResponse.data.statusCode === 200) toast.success("Delivery charges updates successfully"); 
        dispatch(getDeliveryCharges());
    })(dispatch);
};