import * as auth from "./auth";
import * as banners from "./banner";
import * as bookings from "./bookings";
import * as commission from "./commissions";
import * as delivery from "./delivery";
import * as offers from "./offers";
import * as orders from "./orders";
import * as products from "./products";
import * as ratings from "./ratings";
import * as staticContent from "./staticContent";
import * as subAdmin from "./subAdmin";
import * as users from "./users";
import * as subscriptions from "./subscriptions";
import * as masterOtp from "./masterOtp";

export default {
    ...auth,
    ...subAdmin,
    ...staticContent,
    ...users,
    ...bookings,
    ...products,
    ...offers,
    ...delivery,
    ...ratings,
    ...orders,
    ...commission,
    ...banners,
    ...subscriptions,
    ...masterOtp,
};
export const IS_LOADING = "IS_LOADING";
export const REDIRECT_PATH = "REDIRECT_PATH";
