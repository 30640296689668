import types from '../types';
import { store } from '../storeConfig/store';
import { saveUser, getUser } from "../../utility/utils";
import { getSubAdminAPI, updateSubAdminAPI, createSubAdminAPI, deleteSubAdminAPI, getAdminAPI } from '../api/subAdmin';

const { dispatch } = store;

export function getAdmin() {
    return new Promise((resolve, reject) => getAdminAPI()
        .then(res => {
            saveUser({...getUser(), ...res.data.data})
            return resolve(res.data);
        })
        .catch(err => {
            return reject(err);
        })
    )
}

export function getSubAdmin(payload) {
    dispatch({
        type: types.GET_SUBADMIN
    });

    return new Promise((resolve, reject) => getSubAdminAPI(payload)
        .then(res => {
            dispatch({
                type: types.GET_SUBADMIN_SUCCESS,
                payload: res.data.data
            });

            return resolve(res.data);
        })
        .catch(err => {
            dispatch({
                type: types.GET_SUBADMIN_FAILED
            });

            return reject(err);
        })
    )
}

export function updateSubAdmin(payload) {
    dispatch({
        type: types.GET_SUBADMIN
    });
    dispatch({
        type: types.UPDATE_SUBADMIN,
        payload: payload.id
    });

    return new Promise((resolve, reject) => updateSubAdminAPI(payload)
        .then(res => {
            dispatch({
                type: types.UPDATE_SUBADMIN_SUCCESS,
                payload: res.data.data
            });

            return resolve(res.data);
        })
        .catch(err => {
            dispatch({
                type: types.UPDATE_SUBADMIN_FAILED
            });

            return reject(err);
        })
    )
}

export function deleteSubAdmin(payload) {
    dispatch({
        type: types.DELETE_SUBADMIN,
        payload: payload._id
    });

    return new Promise((resolve, reject) => deleteSubAdminAPI(payload._id)
        .then(res => {
            dispatch({
                type: types.UPDATE_SUBADMIN_SUCCESS,
                payload: res.data.data
            });

            return resolve(res.data);
        })
        .catch(err => {
            dispatch({
                type: types.UPDATE_SUBADMIN_FAILED
            });

            return reject(err);
        })
    )
}

export function createSubAdmin(payload) {
    dispatch({
        type: types.CREATE_SUBADMIN
    });

    return new Promise((resolve, reject) => createSubAdminAPI(payload)
        .then(res => {
            dispatch({
                type: types.CREATE_SUBADMIN_SUCCESS,
                payload: res.data.data
            });

            return resolve(res.data);
        })
        .catch(err => {
            dispatch({
                type: types.CREATE_SUBADMIN_FAILED
            });

            return reject(err);
        })
    )
}