import { axiosInstance as axios } from "../../utility/utils";
import { domain } from "../../utility/constants";

export const getCommissionApi = () => axios.get(`${domain}/admin/static/commission`);

export const getTotalCommissionApi = () => axios.get(`${domain}/admin/orders/totalComission/Earned`);

export const getCommissionListApi = (page) => axios.get(`${domain}/admin/orders/seller/orderList?limit=10${page ? `&page=${page}` : ""}`);

export const getCommissionCSVApi = () => axios.get(`${domain}/admin/orders/seller/orderList`);

export const updateCommissionApi = (value) =>
    axios.post(`${domain}/admin/static/commission`, {
        percentageCommission: value,
        topic: "commission",
    });