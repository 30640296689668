import types from '../types';
const initialState = {
    fetching: false,
    aboutUs: null,
    faq: null,
    contactUs: null,
    privacyPolicy: null,
    tnc: null,
    faqPaging: {}
};

export default (state = initialState, action = {}) => {

    switch(action.type) {
        case types.FETCH_CONTENT:
            return { ...state, fetching: action.payload }
        case types.SET_ABOUTUS:
            return { ...state, aboutUs: action.payload }
        case types.SET_CONTACTUS:
            return { ...state, contactUs: action.payload }
        case types.SET_PRIVACY_POLICY:
            return { ...state, privacyPolicy: action.payload }
        case types.SET_TNC:
            return { ...state, tnc: action.payload }
        case types.SET_FAQ:
            return { ...state, faq: action.payload }
        case types.SET_FAQ_PAGING:
            return { ...state, faqPaging: action.payload }
        default:
            return { ...state }
    }

}