import types from "../types";
import {
    getOffersApi,
    updateOfferApi,
    deleteOfferApi,
    createOfferApi,
    uploadOfferImageApi
} from "../api/offers";
import { toast } from "react-toastify";
import { history } from '../../history';

const exceptionWrapper = (cb) => async (dispatch) => {
    try {
        dispatch({
            type: types.FETCHING_OFFERS,
            payload: true,
        });
        await cb();
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({
            type: types.FETCHING_OFFERS,
            payload: false,
        });
    }
};

export const getOffersListing = (filters) => async (dispatch) => {
    await exceptionWrapper(async function getOffersListingInner() {
        const offerResponse = await getOffersApi(filters);
        dispatch({
            type: types.SET_OFFERS,
            payload: offerResponse.data.data.offers,
        });
        dispatch({
            type: types.SET_OFFERS_PAGING,
            payload: offerResponse.data.data.paging,
        });
    })(dispatch);
};

export const updateOffer = (payload, filters) => async (dispatch) => {
    await exceptionWrapper(async function updateOfferInner() {
        const offerResponse = await updateOfferApi(payload);
        if (offerResponse.data.message)
            toast.success(offerResponse.data.message);
        if (filters) await dispatch(getOffersListing(filters));
    })(dispatch);
};

export const deleteOffer = (payload, filters) => async (dispatch) => {
    await exceptionWrapper(async function deleteOfferInner() {
        await deleteOfferApi(payload);
        if (filters) await dispatch(getOffersListing(filters));
    })(dispatch);
};

export const createOffer = (payload, formData) => async (dispatch) => {
    await exceptionWrapper(async function createOfferInner() {
        const offerResponse = await createOfferApi(payload);
        if (offerResponse.data){
            if(offerResponse.data.data && offerResponse.data.data._id) {
                await uploadOfferImageApi(offerResponse.data.data._id, formData);
                if (offerResponse.data.statusCode === 200 && offerResponse.data.message) {
                    history.push("/pages/offers");
                    toast.success(offerResponse.data.message);
                }
            }
        }
    })(dispatch);
};
