import {
    deleteBuyerAPI,
    deleteSellerAPI,
    getBuyerAPI,
    getRatedBuyerAPI,
    getRatedSellerAPI,
    getSellerAPI,
    getTutorProfileAPI,
    updateBuyerAPI,
    updateFssaiAPI,
    updateKitchenDetailsApi,
    updateSellerAPI,
    updateSellerBankApi,
} from "../api/users";
import { store } from "../storeConfig/store";
import types from "../types";

const { dispatch } = store;

export function getBuyer(payload) {
    dispatch({
        type: types.GET_STUDENTS,
    });

    return new Promise((resolve, reject) =>
        getBuyerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.GET_STUDENTS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_STUDENTS_FAILED,
                });

                return reject(err);
            })
    );
}

export function getSeller(payload) {
    dispatch({
        type: types.GET_TUTORS,
    });

    return new Promise((resolve, reject) =>
        getSellerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.GET_TUTORS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}

export function getRatedBuyer(payload) {
    dispatch({
        type: types.GET_RATED_BUYER,
    });

    return new Promise((resolve, reject) =>
        getRatedBuyerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.GET_RATED_BUYER_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_RATED_BUYER_FAILED,
                });

                return reject(err);
            })
    );
}

export function getRatedSeller(payload) {
    dispatch({
        type: types.GET_RATED_SELLER,
    });

    return new Promise((resolve, reject) =>
        getRatedSellerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.GET_RATED_SELLER_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_RATED_SELLER_FAILED,
                });

                return reject(err);
            })
    );
}

export function deleteBuyer(id) {
    dispatch({
        type: types.GET_STUDENTS,
    });

    return new Promise((resolve, reject) =>
        deleteBuyerAPI(id)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}

export function deleteSeller(id) {
    dispatch({
        type: types.GET_STUDENTS,
    });

    return new Promise((resolve, reject) =>
        deleteSellerAPI(id)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}

export function updateSeller(payload) {
    dispatch({
        type: types.GET_TUTORS,
    });
    dispatch({
        type: types.UPDATE_TUTORS,
        payload: payload.id,
    });

    return new Promise((resolve, reject) =>
        updateSellerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.UPDATE_TUTORS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}

export function getTutorProfile(payload) {
    dispatch({
        type: types.GET_TUTOR_PROFILE,
    });

    return new Promise((resolve, reject) =>
        getTutorProfileAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.GET_TUTOR_PROFILE_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.GET_TUTOR_PROFILE_FAILED,
                });

                return reject(err);
            })
    );
}

export function updateBuyer(payload) {
    dispatch({
        type: types.GET_STUDENTS,
    });
    dispatch({
        type: types.UPDATE_STUDENT,
        payload: payload.id,
    });

    return new Promise((resolve, reject) =>
        updateBuyerAPI(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.UPDATE_STUDENT_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_STUDENT_FAILED,
                });

                return reject(err);
            })
    );
}

export function updateFssaiCertificate(id, payload) {
    console.log("payload", payload);
    dispatch({
        type: types.GET_STUDENTS,
    });
    return new Promise((resolve, reject) =>
        updateFssaiAPI(id, payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.UPDATE_STUDENT_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_STUDENT_FAILED,
                });

                return reject(err);
            })
    );
}

export function updateKitchenDetails(payload) {
    dispatch({
        type: types.GET_TUTORS,
    });
    dispatch({
        type: types.UPDATE_TUTORS,
        payload: payload.id,
    });

    return new Promise((resolve, reject) =>
        updateKitchenDetailsApi(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.UPDATE_TUTORS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}

export function updateSellerBank(payload) {
    dispatch({
        type: types.GET_TUTORS,
    });
    dispatch({
        type: types.UPDATE_TUTORS,
        payload: payload.id,
    });

    return new Promise((resolve, reject) =>
        updateSellerBankApi(payload)
            .then((res) => {
                dispatch({
                    payload: res.data.data,
                    type: types.UPDATE_TUTORS_SUCCESS,
                });

                return resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_TUTORS_FAILED,
                });

                return reject(err);
            })
    );
}
