export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAILED = "GET_STUDENTS_FAILED";
export const GET_TUTORS = "GET_TUTORS";
export const GET_TUTORS_SUCCESS = "GET_TUTORS_SUCCESS";
export const GET_TUTORS_FAILED = "GET_TUTORS_FAILED";
export const UPDATE_TUTORS = "UPDATE_TUTORS";
export const UPDATE_TUTORS_SUCCESS = "UPDATE_TUTORS_SUCCESS";
export const UPDATE_TUTORS_FAILED = "UPDATE_TUTORS_FAILED";
export const GET_TUTOR_PROFILE = "GET_TUTOR_PROFILE";
export const GET_TUTOR_PROFILE_SUCCESS = "GET_TUTOR_PROFILE_SUCCESS";
export const GET_TUTOR_PROFILE_FAILED = "GET_TUTOR_PROFILE_FAILED";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILED = "UPDATE_STUDENT_FAILED";

export const GET_RATED_BUYER = "GET_RATED_BUYER";
export const GET_RATED_SELLER = "GET_RATED_SELLER";
export const GET_RATED_BUYER_SUCCESS = "GET_RATED_BUYER_SUCCESS";
export const GET_RATED_SELLER_SUCCESS = "GET_RATED_SELLER_SUCCESS";
export const GET_RATED_BUYER_FAILED = "GET_RATED_BUYER_FAILED";
export const GET_RATED_SELLER_FAILED = "GET_RATED_SELLER_FAILED";

export const UPDATE_KITCHEN_DETAILS = "UPDATE_KITCHEN_DETAILS";
