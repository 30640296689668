export const domain = process.env.REACT_APP_API_URL;
export const alphaRegEx = /^[a-zA-Z ]+$/

export const statusOptions = [
    {label:"All", value:undefined},
    {label:"Verified",value:true},
    {label:"Unverified",value:false}
]

export const bookingData = [
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"Sweden",
        education:"8th grade",
        subject:"math",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"completed"
    },
    {
        posted_on:"29/05/2020",
        posted_by:"ABC",
        country:"India",
        education:"8th grade",
        subject:"Physics",
        session_date:"08/06/2020",
        session_timings:"3:00 - 5:00",
        additional_hours:"3 hrs",
        amount_paid:"100",
        paid_on:"30/05/2020",
        tutor_name:"XYZ",
        status:"pending"
    }
]
