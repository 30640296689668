import types from '../types';

let initialState = {
    fetching: false,
    totalCommission: 0,
    commission: 0,
    commissionList:[],
    paging: {
        page: 0,
        count: 0
    }
}


export default (state = initialState, action={}) => {

    switch (action.type) {
        case types.FETCH_COMMISSION: 
            return { ...state, fetching: action.payload }
        case types.SET_COMMISSION:
            return { ...state, commission: action.payload }
        case types.SET_TOTAL_COMMISSION:
            return { ...state, totalCommission: action.payload }
        case types.SET_COMMISSION_LIST:
            return { ...state, commissionList: action.payload.list, paging: action.payload.paging }
        default:
            return state;
    }
}