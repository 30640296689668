import React, { Fragment, useState } from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import actions from '../../../redux/actions'
import { connect } from 'react-redux'
import { history } from '../../../history'
import CustomModal from '../../../components/custom/CustomModal'
import {getUser} from '../../../utility/utils';

const UserDropdown = props => {
  const user = getUser();
  const {logout = {}} = props;
  const {fetching = false} = logout
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  const logOutUser = () => {
    localStorage.clear();
    history.push("/pages/login")
  }

  return (
    <Fragment>
      <DropdownMenu right>
        <DropdownItem tag="a" onClick={() => history.push("/user/pages/settings")}>
          <Icon.Settings size={14} className="mr-50" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag="a"
          onClick={toggleOpen}
        >
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
      {open ?
        <CustomModal
          open={open}
          toggleModal={toggleOpen}
          headingText={"Logout"}
          paragraphHeading={"Are you sure you want to logout ?"}
          successAction={logOutUser}
          loading={fetching}
          disabled={fetching}
          successText="Log Out"
        />
        : null
      }

    </Fragment>
  )
}

const NavbarUser = (props) => {

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {props.userName}
            </span>
          </div>
          <span data-tour="user">
            <Icon.ChevronDown />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )
}

const mapDispatchToProps = () => {
  return {
    logOutAction: actions.logOutAction
  }
}

const mapStateToProps = (state) => {
  return {
    logout : state.auth.logout
  }
}

export const NavbarUserComp = NavbarUser;

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser)
