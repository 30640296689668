import {
    createBannerApi,
    deleteBannerApi,
    getBannersApi,
} from "../api/banners";
import types from "../types";
// import { toast } from "react-toastify";
// import { history } from "../../history";

const exceptionWrapper = (cb) => async (dispatch) => {
    try {
        dispatch({
            type: types.FETCHING_BANNERS,
            payload: true,
        });
        await cb();
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({
            type: types.FETCHING_BANNERS,
            payload: false,
        });
    }
};

export const fetchBanners = () => async (dispatch) => {
    await exceptionWrapper(async function fetchBannersInner() {
        const res = await getBannersApi();
        dispatch({
            type: types.SET_BANNERS,
            payload: res.data,
        });
    })(dispatch);
};

export const createBanner = () => async (dispatch) => {
    await exceptionWrapper(async function createBannerInner() {
        const res = await createBannerApi();
        dispatch({
            type: types.SET_BANNERS,
            payload: res.data,
        });
    })(dispatch);
};

export const deleteBanner = (id) => async (dispatch) => {
    await exceptionWrapper(async function deleteBannerInner() {
        await deleteBannerApi(id);
        await getBannersApi();
    });
};
