import types from "../types";

let initialState = {
    fetching: true,
    subscriptions: [],
};

const manageSubscriptions = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SUBSCRIPTIONS:
            return { ...state, fetching: true };
        case types.GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                subscriptions: { ...action.payload },
            };
        case types.GET_SUBSCRIPTIONS_FAILED:
            return { ...state, fetching: false };

        default:
            return state;
    }
};

export default manageSubscriptions;
