import { domain } from "../../utility/constants";
import { axiosInstance as axios } from "../../utility/utils";

export const getBannersApi = () => axios.get(`${domain}/admin/banner/banners`);

export const deleteBannerApi = (id) =>
    axios.delete(`${domain}/admin/banner/${id} `);

export const createBannerApi = (payload) =>
    axios.post(`${domain}/admin/banner/banner`, payload);
