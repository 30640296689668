import type from '../types'

const initial_state = {
    fetching: true,
    products: null,
    cuisine: null
}
const products = (state = initial_state, action) => {

    switch(action.type) {

        case type.SET_FETCHING:
            return { ...state, fetching: action.payload }
        case type.GET_PRODUCTS: 
            return { ...state, products: action.payload };
        case type.GET_CUISINE:
            return { ...state, cuisine: action.payload };
        default:
            return { ...state };

    }

}

export default products;