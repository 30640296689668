import types from '../../types';

let initialState = {
    fetching: true,
    sellerData: [],
    tutorInfo: {},
    loading:false,
    retedSellerData: []
}


export const manageSeller = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_TUTORS:
            return { ...state, fetching: true }
        case types.GET_TUTORS_SUCCESS:
            return { ...state, fetching: false, sellerData: { ...action.payload } };
        case types.GET_TUTORS_FAILED:
            return { ...state, fetching: false }
        case types.GET_RATED_SELLER:
            return { ...state, fetching: true }
        case types.GET_RATED_SELLER_SUCCESS:
            return { ...state, fetching: false, ratedSellerData: { ...action.payload } };
        case types.GET_RATED_SELLER_FAILED:
            return { ...state, fetching: false }
        case types.GET_TUTOR_PROFILE:
            return { ...state, loading: true }
        case types.GET_TUTOR_PROFILE_SUCCESS:
            return { ...state, loading: false, tutorInfo: { ...action.payload } };
        case types.GET_TUTOR_PROFILE_FAILED:
            return { ...state, loading: false }
        default:
            return state;
    }
}