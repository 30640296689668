import type from '../types';
import { getProductsApi, createProductApi, updateProductApi, deleteProductApi, productImageApi, cuisineListApi } from '../api/products';
import { toast } from 'react-toastify';

const exceptionWrapper = cb => async (dispatch) => {
    dispatch({
        type: type.SET_FETCHING,
        payload: true
    });
    try {
        await cb();
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({
            type: type.SET_FETCHING,
            payload: false
        });
    }
}

export const getProducts = (payload) => (dispatch) => {
    exceptionWrapper(async function getProductsInnerFunction() {
        const productResponse = await getProductsApi(payload);
        dispatch({
            type: type.GET_PRODUCTS,
            payload: productResponse.data.data
        });
    })(dispatch);
}

export const addProduct = (payload) => async (dispatch) => {
    await exceptionWrapper(async function getProductsInnerFunction() {
        const productResponse = await createProductApi(payload);
        if (productResponse.data && productResponse.data.message) toast.success(productResponse.data.message);
    })(dispatch);
}

export const updateProduct = (id, payload) => async (dispatch) => {
    await exceptionWrapper(async function updateProductsInnerFunction() {
        const productResponse = await updateProductApi(id, payload);
        if (productResponse.data && productResponse.data.message) toast.success(productResponse.data.message);
    })(dispatch);
}

export const deleteProduct = (id) => async (dispatch) => {
    await exceptionWrapper(async function deleteProductsInnerFunction() {
        await deleteProductApi(id);
    })(dispatch);
}

export const updateProductImage = (id, payload) => async (dispatch) => {
    await exceptionWrapper(async function productImageInnerFunction() {
        const productResponse = await productImageApi(id, payload);
        if (productResponse.data && productResponse.data.message) toast.success(productResponse.data.message);
    })(dispatch);
}

export const getCuisineList = () => async (dispatch) => {
    await exceptionWrapper(async function cuisineListInnerFunction() {
        const cuisineResponse = await cuisineListApi();
        dispatch({
            type: type.GET_CUISINE,
            payload: cuisineResponse.data.data
        });
    })(dispatch);
}