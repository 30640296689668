import { axiosInstance as axios } from "../../utility/utils";
import { domain } from "../../utility/constants";

export const getActiveSellerApi = () =>
    axios.get(`${domain}/admin/user/seller/activeList`);

export const getActiveBuyerApi = (params) =>
    axios.get(`${domain}/admin/user/buyer/activeList`, { params });

export const getSellerProductApi = (sellerId) =>
    axios.get(`${domain}/admin/product/sellerProducts/${sellerId}`);

export const getOffersApi = (filters = {}) =>
    axios.get(
        `${domain}/admin/offers/${filters.type}?search=${
            filters.search || ""
        }&page=${filters.page}&limit=${filters.limit}&startDate=${
            filters.startDate
        }&endDate=${filters.endDate}`
    );

export const updateOfferApi = (payload = {}) =>
    axios.put(`${domain}/admin/offers/${payload._id}`, { ...payload.data });

export const deleteOfferApi = (payload = {}) =>
    axios.delete(`${domain}/admin/offers/${payload._id}`);

export const createOfferApi = (payload = {}) =>
    axios.post(`${domain}/admin/offers`, payload);

export const uploadOfferImageApi = (_id, formData) =>
    axios.post(`${domain}/admin/offers/upload/offerImages/${_id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
