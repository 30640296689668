import types from '../types';

const initialState = {
    fetching: false,
    type: "buyer",
    ratings: []
};

export default function ratingReducer(state=initialState, action={}) {
    
    switch(action.type) {

        case types.FETCH_RATINGS:
            return { ...state, fetching: action.payload }
        case types.SET_USER_TYPE:
            return { ...state, type: action.payload }
        case types.SET_RATINGS:
            return { ...state, ratings: action.payload }
        default: 
            return { ...state }

    }

}