import types from '../types';

const initialState = {
    fetching: false,
    offers: [],
    paging: {}
};

export default (state = initialState, action = {}) => {

    switch(action.type) {
        case types.FETCHING_OFFERS: 
            return { ...state, fetching: action.payload }
        case types.SET_OFFERS:
            return { ...state, offers: action.payload }
        case types.SET_OFFERS_PAGING:
            return { ...state, paging: action.payload }
        default:
            return { ...state }

    }

}