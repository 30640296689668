import types from "../types";

const initialState = {
    fetching: false,
    images: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.FETCHING_BANNERS:
            return { ...state, fetching: action.payload };
        case types.SET_BANNERS:
            return { ...state, images: action.payload };
        default:
            return { ...state };
    }
};
