export const FETCH_CONTENT = "FETCH_CONTENT";
export const SET_ABOUTUS = "SET_ABOUTUS";
export const SET_CONTACTUS = "SET_CONTACTUS";
export const SET_PRIVACY_POLICY = "SET_PRIVACY_POLICY";
export const SET_TNC = "SET_TNC";
export const SET_FAQ = "SET_FAQ";
export const SET_FAQ_PAGING = "SET_FAQ_PAGING";
export const CONTENT_KEYS = {
    aboutUs: "aboutUs",
    contactUs: "contactUs",
    tnc: "tnc",
    privacyPolicy: "privacyPolicy"
}
export const contentType = {
    aboutUs: {
        title: "About Us",
        endpoint: "aboutUS",
        action: SET_ABOUTUS
    },
    contactUs: {
        title: "Contact Us",
        endpoint: "contactUs",
        action: SET_CONTACTUS
    },
    tnc: {
        title: "Terms & Condition",
        endpoint: "termsNCondition",
        action: SET_TNC
    },
    privacyPolicy: {
        title: "Privacy Policy",
        endpoint: "privacyPolicy",
        action: SET_PRIVACY_POLICY
    }
};