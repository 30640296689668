import types from "../types";
import { toast } from "react-toastify";
import { getCommissionApi, updateCommissionApi, getTotalCommissionApi, getCommissionListApi } from "../api/commission";

const exceptionWrapper = (cb) => async (dispatch) => {
    try{
        dispatch({
            type: types.FETCH_COMMISSION,
            payload: true
        });
        await cb();
    } catch(e) {
        console.error(e);
    }finally {
        dispatch({
            type: types.FETCH_COMMISSION,
            payload: false,
        });
    }
}

export const getCommission = () => async (dispatch) => {
    await exceptionWrapper(async function getCommissionInner() {
        const commissionResponse = await getCommissionApi();
        dispatch({
            type: types.SET_COMMISSION,
            payload: commissionResponse.data
                ? (commissionResponse.data.data && commissionResponse.data.data.percentageCommission) || 0
                : 0,
        });
    })(dispatch);
}

export const getCommissionList = (page) => async (dispatch) => {
    await exceptionWrapper(async function getCommissionListInner() {
        const commissionResponse = await getCommissionListApi(page);
        dispatch({
            type: types.SET_COMMISSION_LIST,
            payload: {
                list: commissionResponse.data.data.orders,
                paging: {
                    page: commissionResponse.data.data.paging.page,
                    count: commissionResponse.data.data.paging.total[0].count
                },
            },
        });
    })(dispatch);
};

export const getTotalCommission = () => async (dispatch) => {
    await exceptionWrapper(async function getTotalCommissionInner() {
        const commissionResponse = await getTotalCommissionApi();
        dispatch({
            type: types.SET_TOTAL_COMMISSION,
            payload: commissionResponse.data
                ? (commissionResponse.data.data &&
                      commissionResponse.data.data.totalComissionEarned) ||
                  0
                : 0,
        });
    })(dispatch);
};

export const updateCommissionCharges = (value) => async (dispatch) => {
    await exceptionWrapper(async function updateCommissionInner() {
        const commissionResponse = await updateCommissionApi(value);
        if (commissionResponse.data.statusCode === 200)
            toast.success("Commission updated successfully");
    })(dispatch);
};